import React, { useState } from 'react'
import { formatDate } from '../../../helper/dateFormate';

const StudentAccordion = ({ courseName, students, isOpen, onClick, theadData, handleShowCompleteModel, handleShowModal }) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleAccordion = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <>
      <div className="accordion-item">
        <div className={`studentCourseHead ${isOpen ? 'active' : ''}`} onClick={() => onClick()}>
          <h5 className='mb-0'><i className="fa-solid fa-angles-right" /> {courseName}</h5>
        </div>
        {isOpen && (
          <div className="px-3 table-responsive">
            <table className="display dataTable no-footer w-100">
              <thead>
                <tr>
                  {theadData.map((item, ind) => (
                    <th key={ind}>{item.heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {students.map((data, ind) => {
                  return (
                    <tr key={ind}>
                      <td style={{ textWrap: "nowrap" }}>
                        {data._id}
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        <strong>{data.name}</strong>
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        <strong>{data.email}</strong>
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        {data.phoneNumber}
                      </td>
                      {/* <td style={{ textWrap: "nowrap" }}>
                        {data?.courseName}
                      </td> */}
                      <td style={{ textWrap: "nowrap" }}>
                        {data?.courses?.status}
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        {data?.courses?.status === "Pending" && (
                          <button
                            className="btn sharp btn-success"
                            onClick={() => handleShowCompleteModel(data)}
                          >
                            Mark Complete
                          </button>
                        )}
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        {formatDate(data.createdAt)}
                      </td>
                      <td style={{ textWrap: "nowrap" }}>
                        <button className="btn btn-xs sharp btn-danger" onClick={() => handleShowModal(data)}>
                          <i className="fa fa-trash" />
                        </button>
                      </td>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

export default StudentAccordion
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_SUBSCRIBER, GET_SUBSCRIBER } from "../../../constant/apiEndPoint";
import { deleteRequest, getRequest } from "../../../helper/axiosClient";
import { formatDate } from "../../../helper/dateFormate";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import useDebounce from "../../hook/useDebounce ";
import { gridDataBlog } from "../staff/GridData";
import AddSubscribers from "./AddSubscribers";

const theadData = [
  { heading: "Id" },
  { heading: "Email" },
  { heading: "Created Date" },
  { heading: "Action" },
];

const AllSubscribers = () => {
  const [editCategorieData, setEditCategorieData] = useState();
  const [feeData, setFeeDate] = useState([]);
  const [editSubscriberData, setEditSubscriberData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addEditModalShow, setAddEditModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getTableSubscriber = async (search = "") => {
    setIsLoading(true);
    try {
      const params = { page: page, perPage: recordPerPage, search: search };
      const res = await getRequest(GET_SUBSCRIBER, params, navigate, dispatch);
      setFeeDate(res.data.data);
      setTotalPage(res?.data.totalPage);
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTableSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, recordPerPage]);

  const handleShowModal = (data) => {
    setModalShow(true);
    setEditCategorieData(data);
  };

  const handleShowAddEditModal = () => {
    setEditSubscriberData([]);
    setAddEditModalShow(true);
    setSearchTerm("");
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (editCategorieData?._id) {
      try {
        const res = await deleteRequest(
          `${DELETE_SUBSCRIBER}?id=${editCategorieData?._id}`
        );
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          setActivePage(1);
          setPage(1);
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
      } catch (error) {
        console.log("res====>", error);
      }
    }
    setModalShow(false);
    getTableSubscriber();
    setEditCategorieData("");
    setIsLoading(false);
  };

  const handleEditData = (data) => {
    setAddEditModalShow(true);
    setEditSubscriberData(data);
  };

  const debouncedSearch = useDebounce((value) => {
    setPage(1);
    getTableSubscriber(value.trim());
  }, 700);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim());
    debouncedSearch(value.trim());
  };

  const handlePageChange = (pageIndex) => {
    setActivePage(pageIndex);
    setPage(pageIndex);
  };

  const handlePerPageChange = (e) => {
    setRecordPerPage(e);
  };

  return (
    <>
      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane eventKey="List" className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Subscribers List</h4>
                    <button className="btn btn-primary" onClick={handleShowAddEditModal}>+ Add Subscriber</button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div id="holidayList" className="dataTables_wrapper no-footer">
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              Show
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn"
                                >
                                  {recordPerPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(10)}
                                  >
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(20)}
                                  >
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => handlePerPageChange(30)}
                                  >
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              entries
                            </label>
                          </div>
                          <div className="dataTables_filter">
                            <label>
                              Search :{" "}
                              <input
                                type="search"
                                placeholder="Search Subscribers"
                                value={searchTerm}
                                onChange={handleSearchChange}
                              />
                            </label>
                          </div>
                        </div>
                        {isLoading ? (
                          <Loader />
                        ) : feeData.length !== 0 ? (
                          <>
                            <table
                              id="example4"
                              className="display dataTable no-footer w-100"
                            >
                              <thead>
                                <tr>
                                  {theadData.map((item, ind) => (
                                    <th key={ind}>{item.heading}</th>
                                  ))}
                                </tr>
                              </thead>

                              <tbody>
                                {feeData.map((data, ind) => (
                                  <tr key={ind}>
                                    <td style={{ textWrap: 'nowrap' }}>{data._id}</td>
                                    <td style={{ textWrap: 'nowrap' }}><strong>{data.email}</strong></td>
                                    <td style={{ textWrap: 'nowrap' }}>{formatDate(data.createdAt)}</td>
                                    <td style={{ textWrap: 'nowrap' }}>
                                      <button
                                        className="btn btn-xs sharp btn-primary me-1"
                                        onClick={() => handleEditData(data)}
                                      >
                                        <i class="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        className="btn btn-xs sharp btn-danger"
                                        onClick={() => handleShowModal(data)}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Pagination
                              totalPage={totalPage} // Replace with actual total number of pages
                              activePage={activePage}
                              recordPerPage={5} // Replace with actual number of records per page
                              onPageChange={handlePageChange}
                            />
                          </>
                        ) : (
                          <h4 className="text-center">No blogs list found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Grid" className="col-lg-12">
                <div className="row">
                  {gridDataBlog.map((item, ind) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={ind}
                    >
                      <div className="card card-profile">
                        <div className="card-header justify-content-end pb-0 border-0">
                          <Dropdown>
                            <Dropdown.Toggle
                              as="button"
                              className="btn btn-link i-false"
                              type="button"
                            >
                              <span className="dropdown-dots fs--1"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="dropdown-menu dropdown-menu-right border py-0"
                            >
                              <div className="py-2">
                                <Link to={"#"} className="dropdown-item">
                                  Edit
                                </Link>
                                <Link
                                  to={"#"}
                                  className="dropdown-item text-danger"
                                >
                                  Delete
                                </Link>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="card-body pt-2">
                          <div className="text-center">
                            <div className="profile-photo">
                              <img
                                src={item.image}
                                width="100"
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-4 mb-1">{item.name}</h3>
                            <p className="text-muted">{item.subject}</p>
                            <ul className="list-group mb-3 list-group-flush">
                              {item.content.map((data, ind) => (
                                <li
                                  className="list-group-item px-0 d-flex justify-content-between"
                                  key={ind}
                                >
                                  <span className="mb-0">{data.title} :</span>
                                  <strong>{data.subtitle}</strong>
                                </li>
                              ))}
                            </ul>
                            <Link
                              to={"/about-student"}
                              className="btn btn-outline-primary btn-rounded mt-3 px-4"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
            </Tab.Content>
            <CustomModal
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                setEditCategorieData("");
              }}
              size="md"
              content={
                <>
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Confirm delete
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to delete this Subscriber?
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      onClick={() => setModalShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </Modal.Footer>
                </>
              }
            />
            <CustomModal
              show={addEditModalShow}
              onHide={() => {
                setAddEditModalShow(false);
              }}
              size="lg"
              content={
                <>
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      {editSubscriberData.length === 0 ? "Add" : "Edit"} Subscriber
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <AddSubscribers
                      setAddEditModalShow={setAddEditModalShow}
                      editSubscriberData={editSubscriberData}
                      getTableSubscriber={getTableSubscriber}
                    />
                  </Modal.Body>
                </>
              }
            />
          </div>
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllSubscribers;

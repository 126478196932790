import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

export function NavMenuToggle() {
	setTimeout(() => {
		let mainwrapper = document.querySelector("#main-wrapper");
		if (mainwrapper.classList.contains('menu-toggle')) {
			mainwrapper.classList.remove("menu-toggle");
		} else {
			mainwrapper.classList.add("menu-toggle");
		}
	}, 200);
}

const NavHader = () => {
	const [toggle, setToggle] = useState(false);
	const { navigationHader, openMenuToggle, background } = useContext(
		ThemeContext
	);
	return (
		<div className="nav-header">
			<Link to="/dashboard" className="brand-logo">
				{background.value === "dark" || navigationHader !== "color_1" ? (
					<Fragment>
						<svg className="logo-abbr" width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_4_96)">
								<path d="M28.5 38.727L10.0549 29.7293V41.6065L28.501 49.7984L46.9469 41.6065V29.7283L28.5 38.727Z" fill="white" />
								<path d="M57 21.1042L28.5001 7.20154L0 21.1042L28.5001 35.0069L50.2909 24.3771V36.7516H53.6344V22.746L57 21.1042Z" fill="white" />
							</g>
							<defs>
								<clipPath id="clip0_4_96">
									<rect width="57" height="57" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 19" fill="none">
							<text x="0" y="15" font-family="Verdana" font-size="15" fill="white">SageAcademy</text>
						</svg>
					</Fragment>
				) : (
					<Fragment>
						<svg className="logo-abbr" width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_4_96)">
								<path d="M28.5 38.727L10.0549 29.7293V41.6065L28.501 49.7984L46.9469 41.6065V29.7283L28.5 38.727Z" fill="white" />
								<path d="M57 21.1042L28.5001 7.20154L0 21.1042L28.5001 35.0069L50.2909 24.3771V36.7516H53.6344V22.746L57 21.1042Z" fill="white" />
							</g>
							<defs>
								<clipPath id="clip0_4_96">
									<rect width="57" height="57" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 19" fill="none">
							<text x="0" y="15" font-family="Verdana" font-size="15" fill="white">SageAcademy</text>
						</svg>
					</Fragment>
				)}
			</Link>

			<div
				className="nav-control"
				onClick={() => {
					setToggle(!toggle);
					openMenuToggle();
					NavMenuToggle()
				}}
			>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;

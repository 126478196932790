import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "react-clock/dist/Clock.css";
// import TimePickerPicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import 'react-clock/dist/Clock.css';
import { DatePicker } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import ImageUploading from "react-images-uploading";
import * as Yup from "yup";
import {
  ADD_COURSE,
  EDIT_COURSE,
  GET_CATEGORY,
} from "../../../constant/apiEndPoint";
import {
  getRequest,
  patchFormRequest,
  postFormRequest,
} from "../../../helper/axiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";

// import QuillEditor from "./QuillEditor";
import {
  deleteMediaFromS3Bucket,
  uploadMediaToS3Bucket,
} from "../../../helper/awsHelper";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import './quill.css';
import { htmlToText } from "html-to-text";
// import stringToHtml from "string-to-html";

const AddCourses = ({
  setAddEditModalShow,
  editCourseData,
  getTableCourse,
}) => {
  const [categoriesList, setCategoriesList] = useState([]);
  // const [courseImagePreview, setCourseImagePreview] = useState(editCourseData?.courseImg || null);
  let [courseImage, setCourseImage] = useState([]);
  let [tempCourseImage, setTempCourseImage] = useState([]);

  let [speakerImage, setSpeakerImage] = useState([]);
  let [tempSpeakerImage, setTempSpeakerImage] = useState([]);

  let [videos, setVideos] = useState([]);
  let [tempVideos, setTempVideos] = useState([]);

  useEffect(() => {
    // if loading existing aLink
    if (editCourseData?._id) {
      const tempCourseImageList = editCourseData.courseImg.map((item) => {
        return {
          key: item,
          data_url: process.env.REACT_APP_IMAGE_API_URL + item,
        };
      });

      setCourseImage([...tempCourseImageList]);
      setTempCourseImage([...editCourseData.courseImg]);

      if (editCourseData.courseVideo?.length > 0) {
        const tempCourseVideoList = editCourseData.courseVideo?.map((item) => {
          return {
            key: item,
            data_url: process.env.REACT_APP_IMAGE_API_URL + item,
          };
        });

        setVideos([...tempCourseVideoList]);
        setTempVideos([...editCourseData.courseVideo]);
      }

      const tempSpeakerImageList = editCourseData.speakerImg.map((item) => {
        return {
          key: item,
          data_url: process.env.REACT_APP_IMAGE_API_URL + item,
        };
      });

      setSpeakerImage([...tempSpeakerImageList]);
      setTempSpeakerImage([...editCourseData.speakerImg]);
    }
  }, [editCourseData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    // courseImg: Yup.mixed().required("Course Image is required."),
    category: Yup.string().required("Category is required."),
    // speakerImg: Yup.mixed().required("Speaker Image is required."),
    speakerName: Yup.string().required("Speaker Name is required."),
    doctorDetails: Yup.string().required("Speaker Description is required."),
    description: Yup.string().required("Description is required."),
    // doctorDetails: Yup.string().required("Speaker Description is required.").max(3000, 'Must be 3000 characters or less'),
    // description: Yup.string().required("Description is required.").max(5000, 'Must be 5000 characters or less'),
    price: Yup.number()
      .typeError("Price must be a number.")
      .required("Price is required."),
    cpdhours: Yup.number()
      .typeError("CPD Hours must be a number."),
    // .required("CPD Hours is required."),
    lecture: Yup.number()
      .typeError("Lecture must be a number.")
      .required("Lecture is required."),
    activeStatus: Yup.string().required("Status is required."),
    isCertified: Yup.boolean(),
    isAvailable: Yup.boolean(),
    courseStartTime: Yup.string().required("Start Time is required."),
    courseEndTime: Yup.string().required("End Time is required."),
    courseStartDate: Yup.date().required("Start Date is required."),
    courseEndDate: Yup.date().required("End Date is required."),
    aims: Yup.string().required("Aims is required.").max(400, 'Must be 400 characters or less'),
    objectives: Yup.string().required("Objectives is required.").max(400, 'Must be 400 characters or less'),
    learningContent: Yup.string().required("learning Content is required.").max(400, 'Must be 400 characters or less'),
    developmentOutcome: Yup.string().required("Development Outcome is required.").max(400, 'Must be 400 characters or less'),
  });

  const getCategoriesList = async () => {
    try {
      const res = await getRequest(GET_CATEGORY);
      setCategoriesList(res.data.data);
    } catch (error) {
      console.log("res====>", error);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  const initialValues = {
    name: editCourseData?.name || "",
    // courseImg: editCourseData?.courseImg || null,
    category: editCourseData?.category || "",
    // speakerImg: editCourseData?.speakerImg || null,
    speakerName: editCourseData?.speakerName || "",
    description: htmlToText(editCourseData?.description || ""),
    doctorDetails: htmlToText(editCourseData?.doctorDetails || ""),
    price: editCourseData?.price || 0,
    cpdhours: editCourseData?.cpdhours || "",
    lecture: editCourseData?.lecture || "",
    activeStatus: editCourseData?.activeStatus || "",
    isCertified: editCourseData?.isCertified || false,
    isAvailable: editCourseData?.isAvailable || false,
    courseStartTime: editCourseData?.courseStartTime || "",
    courseEndTime: editCourseData?.courseEndTime || "",
    courseStartDate: editCourseData?.courseStartDate || new Date(),
    courseEndDate: editCourseData?.courseEndDate || "",
    aims: htmlToText(editCourseData?.aims || ""),
    objectives: htmlToText(editCourseData?.objectives || ""),
    learningContent: htmlToText(editCourseData?.learningContent || ""),
    developmentOutcome: htmlToText(editCourseData?.developmentOutcome || ""),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values=====>", values);

      let forS3MediaArray = [];
      courseImage = await Promise.all(
        courseImage.map(async (image) => {
          if (image.file) {
            let nameArray = image.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray?.length - 1]
              }`;
            // let waterMarkedUrl = await applyBottomAndCenterWatermark(
            //   image.file
            // );
            forS3MediaArray.push({ ...image, key: key });
            return { ...image, key: key };
          } else {
            tempCourseImage = tempCourseImage.filter(
              (item) => item !== image.key
            );
            return { ...image };
          }
        })
      );
      tempCourseImage.forEach(async (item) => {
        deleteMediaFromS3Bucket(item);
      });

      videos = await Promise.all(
        videos.map(async (image) => {
          if (image.file) {
            let nameArray = image.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray?.length - 1]
              }`;
            // let waterMarkedUrl = await applyBottomAndCenterWatermark(
            //   image.file
            // );
            forS3MediaArray.push({ ...image, key: key });
            return { ...image, key: key };
          } else {
            tempVideos = tempVideos.filter(
              (item) => item !== image.key
            );
            return { ...image };
          }
        })
      );
      tempVideos.forEach(async (item) => {
        deleteMediaFromS3Bucket(item);
      });

      speakerImage = await Promise.all(
        speakerImage.map(async (image) => {
          if (image.file) {
            let nameArray = image.file.name.split(".");
            let key = `${nameArray[0]}_${Date.now()}.${nameArray[nameArray?.length - 1]
              }`;
            // let waterMarkedUrl = await applyBottomAndCenterWatermark(
            //   image.file
            // );
            forS3MediaArray.push({ ...image, key: key });
            return { ...image, key: key };
          } else {
            tempSpeakerImage = tempSpeakerImage.filter(
              (item) => item !== image.key
            );
            return { ...image };
          }
        })
      );
      tempSpeakerImage.forEach(async (item) => {
        deleteMediaFromS3Bucket(item);
      });

      await uploadMediaToS3Bucket([...forS3MediaArray]);
      values.courseImg = courseImage.map((item) => item.key);
      values.courseVideo = videos.map((item) => item.key);
      values.speakerImg = speakerImage.map((item) => item.key);

      if (!editCourseData?._id) {
        try {
          const res = await postFormRequest(ADD_COURSE, values);
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableCourse();
        }
      } else {
        try {
          const res = await patchFormRequest(
            `${EDIT_COURSE}?id=${editCourseData?._id}`,
            values
          );
          console.log(res, "res");
          if (res.type === 1) {
            successNotification(res?.response?.data?.message);
          }
          if (res.type === 2) {
            errorNotification(res.errormessage);
          }
        } catch (error) {
          console.log("res====>", error);
        } finally {
          getTableCourse();
        }
      }
      setAddEditModalShow(false);
    },
  });

  const handleChangeCourseImageUpload = (e) => {
    setCourseImage(e);
  };

  const handleChangeSpeakerImageUpload = (e) => {
    setSpeakerImage(e);
  };

  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    const videoFiles = files.filter(file => file.type.includes('video'));

    const videoURLs = videoFiles.map(file => ({
      file,
      data_url: URL.createObjectURL(file),
      key: file.name,
    }));

    setVideos(videoURLs);
  };

  const handleRemoveVideo = (index) => {
    setVideos(prevVideos => prevVideos.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="card mb-0"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <div className="card-body p-1">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        placeholder="Name"
                        id="name"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("name")}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div style={{ color: "red" }}>{formik.errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label className="form-label d-block">Course Image</label>
                      <ImageUploading
                        multiple={false}
                        value={courseImage}
                        onChange={handleChangeCourseImageUpload}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageUpdate,
                          onImageRemove,
                          dragProps,
                        }) => (
                          <div
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                              display: "flex",
                            }}
                            className="upload__image-wrapper"
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                margin: "0px0px 8px 0px",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                height: "80px",
                                width: "80px",
                                color: "#212121",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              +
                            </div>
                            <div
                              style={{
                                borderLeft: "1px solid #ccc",
                                padding: "0px 0px 0px 8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {imageList.map((image, index) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        position: "relative",
                                        margin: "0px 0px 8px 0px",
                                      }}
                                      key={index}
                                    >
                                      <img
                                        alt="closeIcon"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          borderRadius: "8px",
                                        }}
                                        src={image.data_url}
                                      ></img>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "2px",
                                          right: "2px",
                                          cursor: "pointer",
                                          borderRadius: "20px",
                                          width: '17px',
                                          textAlign: 'center',
                                          background: '#e50b0b',
                                          color: 'white',
                                        }}
                                        onClick={() => {
                                          onImageRemove(index);
                                        }}
                                      >
                                        X
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                      {/* <span className="d-flex" style={{ columnGap: "10px" }}>
                        <input
                          id="courseImg"
                          type="file"
                          className="form-control"
                          onChange={handleChangeImageUpload}
                        />
                        {courseImage && courseImage.length > 0 && (
                          <div className="image-preview">
                            <img
                              src={courseImage[0].data_url}
                              alt="Course Preview"
                              className="img-thumbnail-image"
                            />
                          </div>
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label className="form-label d-block">Course Video</label>
                      <div className="">
                        <input
                          type="file"
                          accept="video/*"
                          multiple
                          onChange={handleVideoUpload}
                          style={{ display: 'none' }}
                          id="courseVideo"
                        />

                        <div
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "8px",
                            display: "flex",
                          }}
                          className="upload__image-wrapper"
                        >
                          <div
                            style={{
                              fontSize: "24px",
                              margin: "0px0px 8px 0px",
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              height: "80px",
                              minWidth: "80px",
                              color: "#212121",
                            }}
                            onClick={() => document.getElementById('courseVideo').click()}
                          >
                            +
                          </div>
                          <div
                            style={{
                              borderLeft: "1px solid #ccc",
                              padding: "0px 0px 0px 8px",
                              display: "flex",
                              alignItems: "center",
                              width: "70%",
                            }}
                          >
                            {videos?.length > 0 && videos.map((image, index) => {
                              return (
                                <>
                                  <div style={{ position: "relative", margin: "0px 0px 8px 0px", }} key={index}>
                                    {videos?.length > 0 && videos?.map((video, index) => (
                                      <div key={index} className="video-item">
                                        <p>{video.key}</p>
                                        {/* <video width="300" controls style={{width: "100%",borderRadius: "8px",}}>
                                          <source src={video.data_url} />
                                          Your browser does not support the video tag.
                                        </video> */}
                                      </div>
                                    ))}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "102%",
                                        cursor: "pointer",
                                        borderRadius: "20px",
                                        width: '17px',
                                        textAlign: 'center',
                                        background: '#e50b0b',
                                        color: 'white',
                                      }}
                                      onClick={() => {
                                        handleRemoveVideo(index);
                                      }}
                                    >
                                      X
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>

                        {/* <button type="button"
                          onClick={() => document.getElementById('courseVideo').click()}
                        >
                          Click or Drop here
                        </button>
                        <button type="button" onClick={() => setVideos([])}>Remove all videos</button>
                        <div className="video-list">
                          {videos?.length > 0 && videos?.map((video, index) => (
                            <div key={index} className="video-item">
                              {console.log(video.data_url, video.url)}
                              <video width="300" controls>
                                <source src={video.data_url} />
                                Your browser does not support the video tag.
                              </video>
                              <div className="video-item__btn-wrapper">
                                <button onClick={() => handleRemoveVideo(index)}>Remove</button>
                              </div>
                            </div>
                          ))}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="category">
                        Category
                      </label>
                      <select
                        className="form-select"
                        id="category"
                        {...formik.getFieldProps("category")}
                      >
                        <option value="" disabled>
                          Select category
                        </option>
                        {categoriesList?.map((data) => (
                          <option key={data._id} value={data._id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.category && formik.errors.category ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.category}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="price">
                        Price
                      </label>
                      <input
                        placeholder="Price"
                        id="price"
                        type="number"
                        className="form-control"
                        min={0}
                        {...formik.getFieldProps("price")}
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.price}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="cpdhours">
                        CPD Hours
                      </label>
                      <input
                        placeholder="CPD Hours"
                        id="cpdhours"
                        type="text"
                        className="form-control"
                        min={0}
                        {...formik.getFieldProps("cpdhours")}
                      />
                      {formik.touched.cpdhours && formik.errors.cpdhours ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.cpdhours}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="speakerName">
                        Speaker Name
                      </label>
                      <input
                        placeholder="Speaker name"
                        id="speakerName"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("speakerName")}
                      />
                      {formik.touched.speakerName &&
                        formik.errors.speakerName ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.speakerName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group fallback w-100">
                      <label
                        className="form-label d-block"
                        htmlFor="speakerImg"
                      >
                        Speaker Image
                      </label>
                      <ImageUploading
                        multiple={false}
                        value={speakerImage}
                        onChange={handleChangeSpeakerImageUpload}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageUpdate,
                          onImageRemove,
                          dragProps,
                        }) => (
                          <div
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                              display: "flex",
                            }}
                            className="upload__image-wrapper"
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                margin: "0px0px 8px 0px",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                height: "80px",
                                width: "80px",
                                color: "#212121",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              +
                            </div>
                            <div
                              style={{
                                borderLeft: "1px solid #ccc",
                                padding: "0px 0px 0px 8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {imageList.map((image, index) => (
                                <>
                                  <div
                                    style={{
                                      position: "relative",
                                      margin: "0px 0px 8px 0px",
                                    }}
                                    key={index}
                                  >
                                    <img
                                      alt="closeIcon"
                                      style={{
                                        width: "80px",
                                        height: "80px",
                                        borderRadius: "8px",
                                      }}
                                      src={image.data_url}
                                    ></img>
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        cursor: "pointer",
                                        borderRadius: "20px",
                                        width: '17px',
                                        textAlign: 'center',
                                        background: '#e50b0b',
                                        color: 'white',
                                      }}
                                      onClick={() => {
                                        onImageRemove(index);
                                      }}
                                    >
                                      X
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                      {/* <span className="d-flex" style={{ columnGap: "10px" }}>
                        <input
                          id="speakerImg"
                          type="file"
                          className="form-control"
                          onChange={handlecSpeakerImageChange}
                        />
                        {speakerImagePreview && (
                          <div className="image-preview">
                            <img
                              src={speakerImagePreview}
                              alt="Speaker Preview"
                              className="img-thumbnail-image"
                            />
                          </div>
                        )}
                      </span> */}
                      {formik.touched.speakerImg && formik.errors.speakerImg ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.speakerImg}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="doctorDetails">
                        Speaker Description
                      </label>
                      <ReactQuill
                        value={formik.values.doctorDetails}
                        onChange={(value) =>
                          formik.setFieldValue("doctorDetails", value)
                        }
                      />
                      {formik.touched.doctorDetails &&
                        formik.errors.doctorDetails ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.doctorDetails}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="description">
                        Course Description
                      </label>
                      <ReactQuill
                        value={formik.values.description}
                        onChange={(value) =>
                          formik.setFieldValue("description", value)
                        }
                      />
                      {formik.touched.description &&
                        formik.errors.description ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="lecture">
                        Lecture
                      </label>
                      <input
                        placeholder="Lecture"
                        id="lecture"
                        type="text"
                        className="form-control"
                        {...formik.getFieldProps("lecture")}
                      />
                      {formik.touched.lecture && formik.errors.lecture ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.lecture}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="activeStatus">
                        Status
                      </label>
                      <select
                        className="form-select"
                        id="activeStatus"
                        {...formik.getFieldProps("activeStatus")}
                      >
                        <option value="" disabled>
                          Select status
                        </option>
                        <option value="live">Live</option>
                        <option value="upcoming">Upcoming</option>
                      </select>
                      {formik.touched.activeStatus &&
                        formik.errors.activeStatus ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.activeStatus}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isCertified"
                        {...formik.getFieldProps("isCertified")}
                        checked={formik.values.isCertified}
                      />
                      <label className="form-check-label" htmlFor="isCertified">
                        Certificate
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isAvailable"
                        {...formik.getFieldProps("isAvailable")}
                        checked={formik.values.isAvailable}
                      />
                      <label className="form-check-label" htmlFor="isAvailable">
                        Available
                      </label>
                    </div>
                  </div>
                  {formik.values.activeStatus && (
                    <>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="courseStartDate"
                          >
                            Start Date
                          </label>
                          <div className="input-hasicon mb-xl-0 mb-3">
                            <DatePicker
                              selected={formik.values.courseStartDate}
                              onChange={(date) =>
                                formik.setFieldValue("courseStartDate", date)
                              }
                              placeholderText="Start Date"
                              format="dd-MM-yyyy"
                              preventOverflow
                              className="picker-suit"
                              // shouldDisableDate={(date) =>
                              //   isBefore(date, new Date().setHours(0, 0, 0, 0))
                              // }
                              defaultValue={
                                editCourseData?.courseStartDate
                                  ? new Date(formik.values.courseStartDate)
                                  : new Date()
                              }
                            // disabled={formik.values.activeStatus === "live"}
                            />
                            <div className="icon">
                              <i className="far fa-calendar" />
                            </div>
                          </div>
                          {formik.touched.courseStartDate &&
                            formik.errors.courseStartDate ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseStartDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="courseEndDate">
                            End Date
                          </label>
                          <div className="input-hasicon mb-xl-0 mb-3">
                            <DatePicker
                              size="md"
                              preventOverflow
                              // shouldDisableDate={(date) =>
                              //   isBefore(date, new Date().setHours(0, 0, 0, 0))
                              // }
                              format="dd-MM-yyyy"
                              selected={new Date(formik.values.courseEndDate)}
                              defaultValue={
                                editCourseData.courseEndDate
                                  ? new Date(formik.values.courseEndDate)
                                  : null
                              }
                              onChange={(date) =>
                                formik.setFieldValue("courseEndDate", date)
                              }
                              placeholderText="End Date"
                              className="picker-suit"
                            />
                            <div className="icon">
                              <i className="far fa-calendar" />
                            </div>
                            {formik.touched.courseEndDate &&
                              formik.errors.courseEndDate ? (
                              <div style={{ color: "red" }}>
                                {formik.errors.courseEndDate}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group picker-data">
                          <label
                            className="form-label"
                            htmlFor="courseStartTime"
                          >
                            Start Time
                          </label>
                          <div className="color-time-picker style-1">
                            <input type="time" name="courseStartTime"
                              className="form-control"
                              onChange={(e) => {
                                formik.setFieldValue("courseStartTime", e.target.value);
                              }}
                              value={formik.values.courseStartTime} />
                            {/* <TimePickerPicker
                              selected={formik.values.courseStartTime}
                              name="courseStartTime"
                              onChange={(value) => {
                                formik.setFieldValue("courseStartTime", value);
                              }}
                              value={formik.values.courseStartTime}
                            /> */}
                          </div>
                          {formik.touched.courseStartTime &&
                            formik.errors.courseStartTime ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseStartTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="form-group picker-data">
                          <label className="form-label" htmlFor="courseEndTime">
                            End Time
                          </label>
                          <div className="color-time-picker style-1">
                            <input type="time" name="courseEndTime"
                              className="form-control"
                              onChange={(e) => {
                                formik.setFieldValue("courseEndTime", e.target.value);
                              }}
                              value={formik.values.courseEndTime} />
                            {/* <TimePickerPicker
                              name="courseEndTime"
                              onChange={(value) => {
                                formik.setFieldValue("courseEndTime", value);
                              }}
                              value={formik.values.courseEndTime}
                            /> */}
                          </div>
                          {formik.touched.courseEndTime &&
                            formik.errors.courseEndTime ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.courseEndTime}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}

                  <h4>Certificate Fields</h4>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="aims">
                        Aims
                      </label>
                      <ReactQuill
                        value={formik.values.aims}
                        onChange={(value) =>
                          formik.setFieldValue("aims", value)
                        }
                      />
                      {formik.touched.aims &&
                        formik.errors.aims ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.aims}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="objectives">
                        Objectives
                      </label>
                      <ReactQuill
                        value={formik.values.objectives}
                        onChange={(value) =>
                          formik.setFieldValue("objectives", value)
                        }
                      />
                      {formik.touched.objectives &&
                        formik.errors.objectives ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.objectives}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="learningContent">
                        Learning Content
                      </label>
                      <ReactQuill
                        value={formik.values.learningContent}
                        onChange={(value) =>
                          formik.setFieldValue("learningContent", value)
                        }
                      />
                      {formik.touched.learningContent &&
                        formik.errors.learningContent ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.learningContent}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="developmentOutcome">
                        Development Outcomes
                      </label>
                      <ReactQuill
                        value={formik.values.developmentOutcome}
                        onChange={(value) =>
                          formik.setFieldValue("developmentOutcome", value)
                        }
                      />
                      {formik.touched.developmentOutcome &&
                        formik.errors.developmentOutcome ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.developmentOutcome}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-12 text-end mt-3">
                    <button
                      type="button"
                      className="btn btn-danger light"
                      onClick={() => {
                        formik.handleReset();
                        setAddEditModalShow(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary ms-2">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCourses;

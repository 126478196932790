import moment from "moment";

export const formatDate = (dateString) => {
    // Parse the date string using Moment.js
    let parsedDate = moment(dateString);

    // Format the date as per your requirement
    let formattedDate = parsedDate.format("DD/MM/YYYY");

    return formattedDate;
};
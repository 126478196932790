import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { GET_COUNT, GET_STUDENT } from "../../../constant/apiEndPoint";
import { getRequest } from "../../../helper/axiosClient";
import { formatDate } from "../../../helper/dateFormate";
import CustomModal from "../../components/CustomModal";
import Loader from "../../components/Loader";

const theadData = [
  { heading: "Id" },
  { heading: "Name" },
  { heading: "Email Address" },
  { heading: "Phone Number" },
  { heading: "Course Name" },
  { heading: "Created Date" },
];

const Dashboard3 = () => {
  const [studentData, setStudentData] = useState([]);
  const [countData, setCountData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCount, setIsLoadingCount] = useState(false);

  const getStudentList = async () => {
    setIsLoading(true);
    try {
      const res = await getRequest(GET_STUDENT);
      setStudentData(res.data.data);
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCountList = async () => {
    setIsLoadingCount(true);
    try {
      const res = await getRequest(GET_COUNT);
      if (res.data.status === 1) {
        setCountData(res.data.data);
      }
    } catch (error) {
      console.log("res====>", error);
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    getStudentList();
    getCountList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-xl-3">
          <div className="widget-stat card bg-primary overflow-hidden">
            <div className="card-header border-opacity">
              <h3 className="card-title text-white">Total Students</h3>
              {isLoadingCount ? (
                <div style={{ transform: "scale(0.7)" }}>
                  <Loader className="bg-transparent" />
                </div>
              ) : (
                <h5 className="text-white mb-0">
                  {countData?.totalStudent || 0}
                </h5>
              )}
            </div>
            <div className="card-body text-center mt-3"></div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="widget-stat card bg-success overflow-hidden">
            <div className="card-header border-opacity pb-3">
              <h3 className="card-title text-white">Total Course</h3>
              {isLoadingCount ? (
                <div style={{ transform: "scale(0.7)" }}>
                  <Loader className="bg-transparent" />
                </div>
              ) : (
                <h5 className="text-white mb-0">
                  {countData?.totalCourse || 0}
                </h5>
              )}
            </div>
            <div className="card-body text-center mt-3"></div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="widget-stat card bg-secondary overflow-hidden">
            <div className="card-header border-opacity">
              <h3 className="card-title text-white">New Students</h3>
              {isLoadingCount ? (
                <div style={{ transform: "scale(0.6)" }}>
                  <Loader className="bg-transparent" />
                </div>
              ) : (
                <h5 className="text-white mb-0">
                  {countData?.newStudent || 0}
                </h5>
              )}
            </div>
            <div className="card-body text-center mt-3"></div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="widget-stat card bg-danger overflow-hidden">
            <div className="card-header border-opacity pb-3">
              <h3 className="card-title text-white">Fees Collection</h3>
              {isLoadingCount ? (
                <div style={{ transform: "scale(0.6)" }}>
                  <Loader className="bg-transparent" />
                </div>
              ) : (
                <h5 className="text-white mb-0">
                  {countData?.feeCollection || 0}{" "}$
                </h5>
              )}
            </div>
            <div className="card-body text-center mt-3"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">New Students List </h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {isLoading ? (
                  <Loader />
                ) : studentData.length !== 0 ? (
                  <div
                    id="holidayList"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="example4"
                      className="display dataTable no-footer w-100"
                    >
                      <thead>
                        <tr>
                          {theadData.map((item, ind) => (
                            <th key={ind}>{item.heading}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {" "}
                        {studentData && studentData?.map((data, ind) => {
                          const courseNames = data.course && data.course.map(course => course.name).join(', ');
                          return (
                            <tr key={ind}>
                              <td style={{ textWrap: 'nowrap' }}>{data._id}</td>
                              <td style={{ textWrap: 'nowrap' }}>
                                <strong>{data.name}</strong>
                              </td>
                              <td style={{ textWrap: 'nowrap' }}>
                                <strong>{data.email}</strong>
                              </td>
                              <td style={{ textWrap: 'nowrap' }}>{data.phoneNumber}</td>
                              <td style={{ textWrap: 'nowrap' }}>{courseNames}</td>
                              <td style={{ textWrap: 'nowrap' }}>{formatDate(data.createdAt)}</td>
                            </tr>
                          )
                        })}{" "}
                      </tbody>
                    </table>
                    <CustomModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      size="md"
                      content={
                        <>
                          <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                              Confirm delete
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Are you sure you want to delete Student?
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              class="btn btn-secondary"
                              onClick={() => setModalShow(false)}
                            >
                              Cancel
                            </button>
                            <button type="button" class="btn btn-danger">
                              Delete
                            </button>
                          </Modal.Footer>
                        </>
                      }
                    />
                  </div>
                ) : (
                  <h4 className="text-center">No Student list found</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard3;

// src/CustomModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';

function CustomModal({ show, onHide, size = "lg", content }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {content}
        </Modal>
    );
}

export default CustomModal;

import axios from "axios";
import { LOGIN_ROUTE } from "../constant/route";
import { getCurrentUser } from "../utils/localStorage/getCurrentUser";
import { errorNotification } from "./notification";
import { Logout } from "../store/actions/AuthActions";
const baseURL = process.env.REACT_APP_API_URL;
const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function unAuthorized(navigate, dispatch) {
  localStorage.clear();
  dispatch(Logout(navigate));
  navigate(LOGIN_ROUTE);
}

export function getRequest(URL, params, navigate, dispatch) {
  axiosClient.defaults.params = params;
  axiosClient.defaults.headers.Authorization = `Bearer ${getCurrentUser()?.idToken}`;
  return axiosClient
    .get(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate, dispatch);
      }

      const errors = error?.message;
      const errormessage = error?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

// POST request function
export function postRequest(URL, payload, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${getCurrentUser()?.idToken
    }`;
  return axiosClient
    .post(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}
export function postFormRequest(URL, payload, navigate) {
  const token = getCurrentUser()?.idToken;
  // Create a new Axios instance for each request to avoid overriding headers globally
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      // "Content-Type": "multipart/form-data",
      // Accept: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return axiosInstance
    .post(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        errorNotification(error.response.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function patchFormRequest(URL, payload, navigate) {
  const token = getCurrentUser()?.idToken;
  // Create a new Axios instance for each request to avoid overriding headers globally
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      // "Content-Type": "multipart/form-data",
      // Accept: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return axiosInstance
    .patch(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        errorNotification(error.response.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function putRequest(URL, payload, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${getCurrentUser()?.idToken
    }`;
  return axiosClient
    .put(`/${URL}`, payload)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}

export function patchRequest(URL, payload) {
  axiosClient.defaults.headers.Authorization = `Bearer ${getCurrentUser()?.idToken
    }`;
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, navigate) {
  axiosClient.defaults.headers.Authorization = `Bearer ${getCurrentUser()?.idToken
    }`;
  return axiosClient
    .delete(`/${URL}`)
    .then((response) => {
      return { type: 1, response };
    })
    .catch((error) => {
      if (error.response.status === 401) {
        errorNotification(error?.response?.data?.message);
        unAuthorized(navigate);
      }
      const errors = error?.message;
      const errormessage = error?.response?.data?.message;
      return { type: 2, errors, errormessage };
    });
}
